import React from "react";
import { Header as CommonHeader } from "@sempra-event-registration/common";
import UserMenu from "../UserMenu/UserMenu";

function Header(props) {
  return (
    <CommonHeader {...props}>
      <UserMenu />
    </CommonHeader>
  );
}

export default Header;
