import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Loading,
  UserProvider,
  RemoteConfigProvider,
  useRemoteConfigFlags,
  EventRegistrationThemeProvider,
  EventPage,
  Footer,
  ScrollToTop,
} from "@sempra-event-registration/common";
import ProtectedRoute from "./auth/component/ProtectedRoute";
import Header from "./components/Header/Header";
import EventsPage from "./event/page/EventsPage/EventsPage";
import SignedOut from "./components/UserMenu/SignedOut";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <EventRegistrationThemeProvider company="sempra">
          {children}
        </EventRegistrationThemeProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const flags = useRemoteConfigFlags();
  if (flags.status !== "loaded") return <Loading />;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header company="sempra" />
      <main>
        <Routes>
          <Route path="/events" element={<ProtectedRoute />}>
            <Route path="/events/:eventId/*" element={<EventPage />} />
            <Route path="/events" element={<EventsPage />} />
          </Route>
          <Route exact path="/signout" element={<SignedOut />}></Route>
          <Route
            exact
            path="/*"
            element={<Navigate to={`/events${window.location.search}`} />}
          />
        </Routes>
      </main>
      <Footer company="sempra" />
    </BrowserRouter>
  );
}

function App() {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
}

export default App;
